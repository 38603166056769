import style from './index.module.scss'
import template from '@styles/reusable.styles.module.scss';
import { INumbersItem } from '@components/main-page/main-page.state';
import Image from 'next/image';

interface INumbersSectionProps {
    title: string;
    subtitle: string
    items: INumbersItem[];
}


const NumbersSection = ({ title, subtitle, items }: INumbersSectionProps) => {
    return (
        <section className={`${template.section} ${template.section_gray}`}>
            <div className={`${template.container} ${template.container_small} ${style.container}`}>
                <h1 className={`${template.title} ${style.title}`}>
                    {title}
                </h1>
                <div className={`${template.subtitle} ${style.subtitle}`}>
                    {subtitle}
                </div>
            </div>
            <div className={`${template.container} ${template.container_large} ${style.numbers}`}>
                {
                    items.map((item: INumbersItem, index: number) => {
                        return (
                            <div key={index} className={`${style.numbers__item}`}>
                                <Image loading='eager' alt={item.title} src={item.icon} width="384" height="140"/>
                                <div className={`${style.numbers__item__title}`}>
                                    {item.title}
                                </div>
                            </div>
                        )
                    })
                }
                <hr className={`${style.numbers__hr} ${style.numbers__hr_first}`} />
                <hr className={`${style.numbers__hr} ${style.numbers__hr_second}`} />
            </div>
        </section>
    )
}

export default NumbersSection;
